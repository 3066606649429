import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Bestelling } from 'src/app/_models/bestelling';
import { Klant } from 'src/app/_models/klant';

@Component({
  selector: 'app-bestelling',
  templateUrl: './bestelling.component.html',
  styleUrls: ['./bestelling.component.css']
})
export class BestellingComponent implements OnInit {
  bestellingId = "";
  bestelling:Bestelling;
  new = true;

  constructor(private route: ActivatedRoute) { }
  //table forms:
  //https://www.c-sharpcorner.com/article/creating-table-with-reactive-forms-in-angular-9-using-primeng-table2/
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.bestellingId = params.get('id');
      console.log(this.bestellingId);
      
      if(this.bestellingId == "0" || this.bestellingId == ""){
        this.bestelling = new Bestelling();
      }else{
        this.new = false;
        //load bestelling
        //nu temp load:
        let colruyt = new Klant();
        colruyt.klantNaam = "Colruyt Aalst";
        
        let b3 = new Bestelling();
        b3.bestellingId = "67486dfsf6dfq4s6";
        b3.klant = colruyt;
        b3.leverDatum = new Date();
        b3.totalPrice = 524.78;
      }
      
    });
  }

}
