import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faFilm } from '@fortawesome/free-solid-svg-icons';
import { Bestelling } from 'src/app/_models/bestelling';
import { Klant } from 'src/app/_models/klant';

@Component({
  selector: 'app-klanten',
  templateUrl: './klanten.component.html',
  styleUrls: ['./klanten.component.css']
})
export class KlantenComponent implements OnInit {

  //bestelling data
  klanten: Klant[] = [];

  //icons
  filmIcon = faFilm;
  //table data
  displayedColumns = ["klantNaam","edit"];
  
  dataSource = new MatTableDataSource(this.klanten);
  @ViewChild(MatSort) sort: MatSort;
  
  constructor() { }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'klantNaam') {
        return item.klantNaam;
      } else {
        return item[property];
      }
    };
  }

  ngOnInit(): void {
    this.initTestData();
  }

  //temp for demo
  private initTestData(){
    let delhaize = new Klant();
    delhaize.klantId = "sqmfkgjd";
    delhaize.klantNaam = "Delhaize Liederkerke";

    let colruyt = new Klant();
    colruyt.klantId = "sdfqsdf";
    colruyt.klantNaam = "Colruyt Aalst";


    this.klanten.push(delhaize, colruyt);
  }

}
