<div class="col-md-12" *ngIf="new">
    <h2>Nieuwe klant</h2>
</div>

<div class="col-md-12" *ngIf="!new">
    <h2>Klant aanpassen</h2>
</div>
<hr>

<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <form class="row" style="width: 100%">
        <div class="col-md-12 mb-3 d-grid gap-2">
            <button type="button" class="btn btn-primary" [routerLink]="['/klanten']">Opslaan</button>
        </div>

        <div class="col-md-12 mb-3 d-grid gap-2">
            <button type="button" class="btn btn-light">Download aanbodslijst</button>
        </div>
        <div class="col-md-12 mb-4">
            <div class="mb-3">
                <p><strong>Klant naam</strong></p>
                <input type="text" class="form-control" placeholder="Klant naam" />
            </div>

            <div class="mb-3">
                <p><strong>Opmerkingen</strong></p>
                <textarea class="form-control" rows="3" placeholder="Opmerkingen"></textarea>
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <p><strong>Contact gegevens</strong></p>
                <input type="email" class="form-control" placeholder="Contact persoon" />
            </div>
            <div class="mb-3">
                <input type="email" class="form-control" placeholder="E-mail" />
            </div>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="Telefoon" />
            </div>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="GSM" />
            </div>
            
        </div>
        <div class="col-md-6">
            <p><strong>Extra leverkost</strong></p>
            <div class="mb-3">
                <input type="number" class="form-control" placeholder="Extra leverkost" />
            </div>
            <div class="mb-3">
                <p><strong>Actief / Niet actief</strong></p>
                <select class="form-select">
                    <option selected>Actief</option>
                    <option value="1">Niet actief</option>
                </select>
            </div>
        </div>
        <div class="col-md-12">
            <div class="mb-3">
                <p><strong>Adres gegevens</strong></p>
                <input type="text" class="form-control" placeholder="Straat en nummer" />
            </div>
            <div class="mb-3">
                <p><strong></strong></p>
                <input type="text" class="form-control" placeholder="Adreslijn 2" />
            </div>
            <div class="mb-3">
                <p><strong></strong></p>
                <input type="text" class="form-control" placeholder="Postcode" />
            </div>
            <div class="mb-3">
                <p><strong></strong></p>
                <input type="text" class="form-control" placeholder="Woonplaats" />
            </div>
            <div class="mb-3">
                <p><strong></strong></p>
                <input type="text" class="form-control" placeholder="Land" />
            </div>
        </div>
    </form>
</div>

<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <div class="col-md-12">
        <div class="mb-3">
            <p><strong>Gewassen activeren:</strong></p>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Gewas</th>
                    <th scope="col">Actief</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Aardpeer</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>
                
                <tr>
                    <td>Gekleurde wortel</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>

                <tr>
                    <td>Aardappel</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>

                <tr>
                    <td>Bladpeterselie</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>

                <tr>
                    <td>Boerekool</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>

                <tr>
                    <td>Courgette</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>

                <tr>
                    <td>Boontjes</td>
                    <td><input class="form-check-input" type="checkbox" value=""></td>
                </tr>

                <tr>
                    <td>Daikon</td>
                    <td><input class="form-check-input" type="checkbox" value=""></td>
                </tr>

                <tr>
                    <td>Aardpeer</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>
                
                <tr>
                    <td>Gekleurde wortel</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>

                <tr>
                    <td>Aardappel</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>

                <tr>
                    <td>Bladpeterselie</td>
                    <td><input class="form-check-input" type="checkbox" value="" checked></td>
                </tr>

                <tr>
                    <td>Boerekool</td>
                    <td><input class="form-check-input" type="checkbox" value=""></td>
                </tr>

                <tr>
                    <td>Courgette</td>
                    <td><input class="form-check-input" type="checkbox" value=""></td>
                </tr>

                <tr>
                    <td>Boontjes</td>
                    <td><input class="form-check-input" type="checkbox" value=""></td>
                </tr>

                <tr>
                    <td>Daikon</td>
                    <td><input class="form-check-input" type="checkbox" value=""></td>
                </tr>
                                  
            </tbody>
        </table>
    </div>
</div>

<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <div class="col-md-12">
        <div class="text-center mt-3 mb-2">
            <h3><i class="fas fa-arrow-left"></i><span class="p-5">Overschreven aanbodslijst voor period 12/04/2021 - 18/04/2021</span><i class="fas fa-arrow-right"></i></h3>

        </div>
        <div class="mb-3">
            <p><strong>Prijs factor:</strong></p>
            <input type="text" class="form-control" placeholder="Prijs factor" />
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Gewas</th>
                    <th scope="col">Verpakkingstype</th>
                    <th scope="col">Prijs</th>
                    <th scope="col">Basis Prijs</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Aardpeer</td>
                    <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.2"/></td>
                </tr>
                <tr>
                    <td>Aardpeer</td>
                    <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.5"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.4"/></td>
                </tr>
                <tr>
                    <td>Gekleurde wortel</td>
                    <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.1"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.5"/></td>
                </tr>
                <tr>
                    <td>Aardpeer</td>
                    <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="0.9"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="0.6"/></td>
                </tr>
                <tr>
                    <td>Aardpeer</td>
                    <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="2.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="2.85"/></td>
                </tr>
                <tr>
                    <td>Gekleurde wortel</td>
                    <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="3.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.2"/></td>
                </tr>
                <tr>
                    <td>Aardpeer</td>
                    <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.4"/></td>
                </tr>
                <tr>
                    <td>Aardpeer</td>
                    <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.4"/></td>
                </tr>
                <tr>
                    <td>Gekleurde wortel</td>
                    <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.5"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.2"/></td>
                </tr>
                <tr>
                    <td>Aardpeer</td>
                    <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.2"/></td>
                </tr>
                <tr>
                    <td>Aardpeer</td>
                    <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.2"/></td>
                </tr>
                <tr>
                    <td>Gekleurde wortel</td>
                    <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.2"/></td>
                </tr>
                <tr>
                    <td>Aardpeer</td>
                    <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.2"/></td>
                </tr>
                <tr>
                    <td>Aardpeer</td>
                    <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.2"/></td>
                </tr>
                <tr>
                    <td>Gekleurde wortel</td>
                    <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="1.2"/></td>
                    <td><input disabled type="number" class="form-control" placeholder="Basis Prijs" value="1.2"/></td>
                    
                </tr>                    
            </tbody>
        </table>
    </div>
</div>

<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <div class="col-md-12">
        <div class="mb-3">
            <p><strong>Leeggoed:</strong></p>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Verpakkingstype</th>
                    <th scope="col">Prijs</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>T_Kist</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="4.5"/></td>
                </tr>
                <tr>
                    <td>M_Kist</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="5.0"/></td>
                </tr>
                <tr>
                    <td>S_Kist</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="6.0"/></td>
                </tr>
                <tr>
                    <td>H_Kist</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="5.4"/></td>
                </tr>
                <tr>
                    <td>L_Kist</td>
                    <td><input type="number" class="form-control" placeholder="Prijs" value="4.2"/></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>