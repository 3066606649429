<div class="col-md-12 text-center">
    <h2><i class="fas fa-arrow-left"></i><span class="p-5">Basis aanbodslijst voor period 12/04/2021 - 18/04/2021</span><i class="fas fa-arrow-right"></i></h2>
</div>
<hr>

<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    
    <form class="row" style="width: 100%">
        <div class="col-md-12 mb-3">
            <button type="button" class="btn btn-primary" style="width: 100%;">Opslaan</button>
        </div>
        <div class="col-md-12">
            <p><strong>Aanbodslijst info:</strong></p>

            <div class="mb-3">
                <textarea class="form-control" rows="3" placeholder="Opmerkingen"></textarea>
            </div>

            
            <br>
        </div>
        <div class="col-md-12">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Gewas</th>
                        <th scope="col">Verpakkingstype</th>
                        <th scope="col">Prijs</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Aardpeer</td>
                        <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Gekleurde wortel</td>
                        <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Gekleurde wortel</td>
                        <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Gekleurde wortel</td>
                        <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Gekleurde wortel</td>
                        <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>
                    <tr>
                        <td>Gekleurde wortel</td>
                        <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                        <td><input type="number" class="form-control" placeholder="Prijs"/></td>
                    </tr>                    
                </tbody>
            </table>
        </div>
    </form>
</div>