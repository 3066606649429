<a [routerLink]="['/klant', 0]" class="mb-3" mat-raised-button>Nieuwe Klant</a>

<div class="row mb-3">
    <div class="col-md-12">
        <p class="mb-0">Filter op klant naam:</p>
        <input type="text"  class="form-control" placeholder="Klant naam">
    </div>
</div>

<table mat-table matSort  class="mat-elevation-z8" [dataSource]="dataSource">
  
    <ng-container matColumnDef="klantNaam">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Klant</th>
        <td mat-cell *matCellDef="let klant">{{klant.klantNaam}}</td>
    </ng-container>
  
    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let klant"><a [routerLink]="['/klant', klant.klantId]"><i style="color: #0d6efd;" class="fa fa-edit"></i></a></td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>  
    
</table>

<mat-paginator class="mat-elevation-z8" [pageSizeOptions]="[30, 50, 100]" showFirstLastButtons></mat-paginator>
