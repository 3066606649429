<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <img class="me-3" src="https://www.delochting.be/images/delochting.png" alt="" height="38">
    <div class="lh-1">
        <h1 class="h6 mb-0 lh-1">Welkom in OAS</h1>
        <small>Je bent momenteel aangemeld als {{user.username}}</small>
    </div>
</div>

<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <div class="lh-1">
        <h1 class="h6 lh-1">Bestellingen</h1>
        <div>
            <a class="btn" routerLink="/bestellingen">Alle bestellingen bekijken</a>
        </div>
        <div>
            <a class="btn" [routerLink]="['/bestelling', 0]">Nieuwe bestelling</a>
        </div>
    </div>
</div>

<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <div class="lh-1">
        <h1 class="h6 lh-1">Klanten</h1>
        <div>
            <a class="btn" routerLink="/klanten">Alle klanten bekijken</a>
        </div>
        <div>
            <a class="btn" [routerLink]="['/klant', 0]">Nieuwe klant</a>
        </div>
    </div>
</div>

<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <div class="lh-1">
        <h1 class="h6 lh-1">Basis aanbodslijst</h1>
        <div>
            <a class="btn" routerLink="/aandbodslijst">Basis aanbodslijst aanpassen</a>
        </div>
    </div>
</div>