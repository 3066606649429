import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BestelLijn } from 'src/app/_models/bestel-lijn';

@Component({
  selector: 'app-aanbodslijst',
  templateUrl: './aanbodslijst.component.html',
  styleUrls: ['./aanbodslijst.component.css']
})
export class AanbodslijstComponent implements OnInit {
  bestellingId = "";
  bestelling:BestelLijn;
  
  constructor(private route: ActivatedRoute) { }
  //table forms:
  //https://www.c-sharpcorner.com/article/creating-table-with-reactive-forms-in-angular-9-using-primeng-table2/
  ngOnInit(): void {
  }

}
