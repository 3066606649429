<div class="col-md-12" *ngIf="new">
    <h2>Nieuwe bestelling</h2>
</div>

<div class="col-md-12" *ngIf="!new">
    <h2>Bestelling aanpassen</h2>
</div>
<hr>

<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <form class="row">
        <div class="col-md-12 mb-3 d-grid gap-2">
            <button type="button" class="btn btn-primary" [routerLink]="['/bestellingen']">Opslaan</button>
        </div>

        <div class="col-md-12 mb-3 d-grid gap-2">
            <button type="button" class="btn btn-danger" [routerLink]="['/bestellingen']">Bestelling Afwerken</button>
        </div>
        <div class="col-md-6 col-sm-12">
            <h3>Totalen</h3>
            <hr>
            <p><strong>
                Totaal excl. BTW</strong>
                <br>
                € 0.00</p>
            <p><strong>
                BTW</strong>
                <br>
                € 0.00</p>
            <p><strong>
                Totaal incl. BTW</strong>
                <br>
                € 0.00 </p>
            <p><strong>
                Bestel nummer</strong>
                <br>
                202100568 </p>
        </div>
        <div class="col-md-6 col-sm-12">
            <h3>Klant en levering</h3>
            <hr>

            <div class="mb-3">
                <select class="form-select">
                    <option selected>Selecteer een klant</option>
                    <option value="1">Delhaize</option>
                    <option value="2">Colruyt</option>
                    <option value="3">Bio planet</option>
                </select>
            </div>

            <div class="mb-3">
                <input type="date" class="form-control" placeholder="Selecteer een datum" />
            </div>

            <div class="mb-3">
                <textarea class="form-control" rows="3" placeholder="Opmerkingen"></textarea>
            </div>
        </div>
        <div class="col-md-12">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Gewas</th>
                        <th scope="col">Verpakkingstype</th>
                        <th scope="col">Prijs</th>
                        <th scope="col">Aantal</th>
                        <th scope="col">Exlc. BTW</th>
                        <th scope="col">BTW</th>
                        <th scope="col">Incl. BTW</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Aardpeer</td>
                        <td>los 5 KG T_Kist Berekend gewicht: 5 KG</td>
                        <td>1.74000</td>
                        <td><input type="number" placeholder="aantal" class="form-control" /></td>
                        <td>1.74000</td>
                        <td>0.3654</td>
                        <td>2.1054</td>
                    </tr>
                    <tr>
                        <td>Aardpeer</td>
                        <td>Krimpfolie Schaal bruin 5 STUKS T_Kist Berekend gewicht: 2.5 KG	</td>
                        <td>1.74000</td>
                        <td><input type="number" placeholder="aantal" class="form-control" /></td>
                        <td>1.74000</td>
                        <td>0.3654</td>
                        <td>2.1054</td>
                    </tr>
                    <tr>
                        <td>Gekleurde wortel</td>
                        <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG</td>
                        <td>1.74000</td>
                        <td><input type="number" placeholder="aantal" class="form-control" /></td>
                        <td>1.74000</td>
                        <td>0.3654</td>
                        <td>2.1054</td>
                    </tr>
                    <tr>
                        <td>Pastinaak</td>
                        <td>Krimpfolie Schaal zwart 6 STUKS T_Kist Berekend gewicht: 3 KG	</td>
                        <td>1.74000</td>
                        <td><input type="number" placeholder="aantal" class="form-control" /></td>
                        <td>1.74000</td>
                        <td>0.3654</td>
                        <td>2.1054</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
</div>


<div class="d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm">
    <div class="col-md-12">
        <div class="mb-3">
            <p><strong>Leeggoed:</strong></p>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Verpakkingstype</th>
                    <th scope="col">Aantal</th>
                    <th scope="col">Excl. BTW</th>
                    <th scope="col">BTW</th>
                    <th scope="col">Incl. BTW</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>T_Kist</td>
                    <td>4</td>
                    <td>11</td>
                    <td>9.24</td>
                    <td>20.24</td>
                </tr>
                <tr>
                    <td>M_Kist</td>
                    <td>6</td>
                    <td>14.5</td>
                    <td>3.045</td>
                    <td>17.545</td>
                </tr>
                <tr>
                    <td>S_Kist</td>
                    <td>3</td>
                    <td>12</td>
                    <td>2.52</td>
                    <td>14.52</td>
                </tr>
                <tr>
                    <td>H_Kist</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>L_Kist</td>
                    <td>1</td>
                    <td>4.6</td>
                    <td>0.945</td>
                    <td>5.545</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>