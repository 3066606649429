import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MainNavigationComponent } from './generic/main-navigation/main-navigation.component';
import { GenericModule } from './generic/generic.module';
import { OasModule } from './oas/oas.module';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './oas/home/home.component';
import { BestellingenComponent } from './oas/bestellingen/bestellingen.component';
import { KlantenComponent } from './oas/klanten/klanten.component';
import { RapportenComponent } from './oas/rapporten/rapporten.component';
import { AuthGuard } from './_helpers/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { fakeBackendProvider } from './_helpers/fake-backend';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { AuthenticationService } from './_services/authentication.service';
import { LoginComponent } from './generic/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BestellingComponent } from './oas/bestelling/bestelling.component';
import { AanbodslijstComponent } from './oas/aanbodslijst/aanbodslijst.component';
import { KlantComponent } from './oas/klant/klant.component';

//wildcard route needs to be at the bottom for the router to work correctly
const appRoutes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'bestellingen', component: BestellingenComponent, canActivate: [AuthGuard]},
  { path: 'bestelling/:id', component: BestellingComponent, canActivate: [AuthGuard]},
  { path: 'aandbodslijst', component: AanbodslijstComponent, canActivate: [AuthGuard]},
  { path: 'klant/:id', component: KlantComponent, canActivate: [AuthGuard]},
  { path: 'klanten', component: KlantenComponent, canActivate: [AuthGuard] },
  { path: 'rapporten', component: RapportenComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: HomeComponent }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

      // provider used to create fake backend
      fakeBackendProvider
  ],
  imports: [
    //created modules 
    BrowserModule,
    GenericModule,
    OasModule,

    //angular modules
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
