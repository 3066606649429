import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { BestellingenComponent } from './bestellingen/bestellingen.component';
import { KlantenComponent } from './klanten/klanten.component';
import { RapportenComponent } from './rapporten/rapporten.component';
import { RouterModule } from '@angular/router';
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { BestellingComponent } from './bestelling/bestelling.component';
import { AanbodslijstComponent } from './aanbodslijst/aanbodslijst.component';
import { KlantComponent } from './klant/klant.component';

@NgModule({
  declarations: [HomeComponent, BestellingenComponent, KlantenComponent, RapportenComponent, BestellingComponent, AanbodslijstComponent, KlantComponent],
  imports: [
    CommonModule,
    RouterModule, 

    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule
  ],
  exports: [
    HomeComponent,
    BestellingenComponent,
    KlantenComponent,
    RapportenComponent,
    BestellingComponent
  ]
})
export class OasModule { }
