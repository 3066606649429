import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Bestelling } from 'src/app/_models/bestelling';
import { Klant } from 'src/app/_models/klant';
import { faFilm } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bestellingen',
  templateUrl: './bestellingen.component.html',
  styleUrls: ['./bestellingen.component.css']
})
export class BestellingenComponent implements AfterViewInit {
  //bestelling data
  bestellingen: Bestelling[] = [];

  //icons
  filmIcon = faFilm;
  //table data
  displayedColumns = ["klantNaam", "totalPrice", "leverDatum", "edit", "status"];
  
  dataSource = new MatTableDataSource(this.bestellingen);
  @ViewChild(MatSort) sort: MatSort;
  
  constructor() { }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'klantNaam') {
        return item.klant.klantNaam;
      } else {
        return item[property];
      }
    };
  }

  ngOnInit(): void {
    this.initTestData();
  }

  //temp for demo
  private initTestData(){
    let delhaize = new Klant();
    delhaize.klantNaam = "Delhaize Liederkerke";

    let colruyt = new Klant();
    colruyt.klantNaam = "Colruyt Aalst";

    let b1 = new Bestelling();
    b1.bestellingId = "6541qf64qds4qs";
    b1.klant = delhaize;
    b1.leverDatum = new Date();
    b1.totalPrice = 1300.50;

    let b2 = new Bestelling();
    b2.bestellingId = "56871ds6dfqs746";
    b2.klant = colruyt;
    b2.leverDatum = new Date();
    b2.totalPrice = 964.68;

    let b3 = new Bestelling();
    b3.bestellingId = "67486dfsf6dfq4s6";
    b3.klant = colruyt;
    b3.leverDatum = new Date();
    b3.totalPrice = 524.78;

    let b4 = new Bestelling();
    b4.bestellingId = "sdfs546dfs546dfs546";
    b4.klant = delhaize;
    let date = new Date();
    date.setDate(date.getDate() - 1);
    b4.leverDatum = date;
    b4.totalPrice = 442.89;

    this.bestellingen.push(b1, b2, b3, b4);
  }

  

}
