<nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light" aria-label="Main navigation">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">De Lochting OAS</a>
        <button class="navbar-toggler p-0 border-0" type="button" data-bs-toggle="offcanvas" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#">Help</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#">Bugs</a>
            </li>
        </ul>
        <div class="d-flex">
            <a class="nav-link right-navlink" aria-current="page" (click)="logout()">Afmelden</a>
        </div>
        </div>
    </div>
</nav>