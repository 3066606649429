<a [routerLink]="['/bestelling', 0]" class="mb-3" mat-raised-button>Nieuwe bestelling</a>


<div class="row mb-3">
    <div class="col-md-4">
        <p class="bold">Filter op status:</p>
        <select class="form-select" aria-label="Default select example">
            <option selected>Openstaand</option>
            <option value="1">Afgewerkt</option>
            <option value="2">Alle</option>
          </select>
    </div>
    <div class="col-md-4">
        <p class="bold">Filter op klant naam:</p>
        <input type="text"  class="form-control" placeholder="Klant naam">
    </div>
    <div class="col-md-2 align-items-center">
        <p class="bold">Van datum:</p>
        <input type="date" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline">
    </div>
    <div class="col-md-2 align-items-center">
        <p class="bold">Tot datum:</p>
        <input type="date" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline">
    </div>
</div>

<table mat-table matSort  class="mat-elevation-z8" [dataSource]="dataSource">
  
    <ng-container matColumnDef="klantNaam">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Klant</th>
        <td mat-cell *matCellDef="let bestelling">{{bestelling.klant.klantNaam}}</td>
    </ng-container>
  
    <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Totaal Prijs</th>
        <td  mat-cell *matCellDef="let bestelling">{{bestelling.totalPrice}}</td>
    </ng-container>
  
    <ng-container matColumnDef="leverDatum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Leverdatum</th>
        <td mat-cell *matCellDef="let bestelling">{{bestelling.leverDatum | date: 'dd MMMM, yyyy' }}</td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Aanpassen</th>
        <td mat-cell *matCellDef="let bestelling"><a [routerLink]="['/bestelling', bestelling.bestellingId]"><i style="color: #0d6efd;" class="fa fa-edit"></i></a></td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let bestelling">Openstaand</td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>  
    
</table>

<mat-paginator class="mat-elevation-z8" [pageSizeOptions]="[30, 50, 100]" showFirstLastButtons></mat-paginator>
