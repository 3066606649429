import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Klant } from 'src/app/_models/klant';

@Component({
  selector: 'app-klant',
  templateUrl: './klant.component.html',
  styleUrls: ['./klant.component.css']
})
export class KlantComponent implements OnInit {

  klantId = "";
  bestelling:Klant;
  new = true;

  constructor(private route: ActivatedRoute) { }
  //table forms:
  //https://www.c-sharpcorner.com/article/creating-table-with-reactive-forms-in-angular-9-using-primeng-table2/
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.klantId = params.get('id');
      console.log(this.klantId);
      
      if(this.klantId == "0" || this.klantId == ""){
        this.bestelling = new Klant();
      }else{
        this.new = false;
        //load bestelling 
        //nu temp load:
        
      }
      
    });
  }


}
